<template>
    <div class="fixed-tab-nav">
        <Loader v-if="isLoading" :text="loaderMessage" />

        <div class="st-wizard-tabs pb-6" :class="{ fixed: scrollPosition > 10 }">
            <b-nav
                tabs
                justified
                align="start"
                class="st-wizard-tabs-nav"
                v-b-scrollspy="260"
            >
                <b-nav-item key="pre" class="nav-item-initial" disabled>
                    <div class="d-flex justify-content-center icon-arrow-right">
                        <i class="far fa-file-alt"></i>
                    </div>
                </b-nav-item>
                <b-nav-item
                    :href="tab.id"
                    v-on:click.prevent="goToSection(tab.ref, $event)"
                    v-for="tab in tabs"
                    :key="tab.index"
                    :disabled="tab.disabled"
                    v-show="tab.show"
                >
                    <div class="d-flex justify-content-center w-100">
                        <i :class="tab.icon"></i>
                        <span class="nav-item-text text-uppercase ml-4">{{
                            $t(tab.name)
                        }}</span>
                    </div>
                </b-nav-item>
            </b-nav>
            <div class="st-wizard-button-group">
                <st-button
                    :disabled="!selectedApplicationType"
                    variant="light"
                    :spinner="isLoadingSaveDraft"
                    :callback="() => saveAsDraft(true, true)"
                >
                    <i class="fa fa-save"></i>
                    {{ $t("APPLICATION.FORM.SAVE_AS_DRAFT_BUTTON") }}
                </st-button>
                <st-button
                    :disabled="cannotDownload"
                    variant="primary"
                    :callback="downloadDocumentByCitizen"
                >
                    <i class="far fa-file"></i
                    >{{ $t("APPLICATION.FORM.DOWNLOAD_BUTTON") }}
                </st-button>
            </div>
        </div>
        <div class="navbar-scroll">
            <application-details-form
                ref="applicationDetailsForm"
                @change="changeApplicationType"
                :appId="applicationTypeId"
            />

            <generate-form
                v-if="formSchema"
                :data="formSchema"
                :value="formData"
                ref="generateForm"
                @modelUpdated="updateFormData"
            ></generate-form>

            <territorial-atlas
                v-if="showTerritorialAtlasComponent"
                :realEstates="realEstatesList"
                :gisFilesLayers="gisFilesLayers"
                :isNew="true"
                :isRealEstateTargetUsed.sync="isRealEstateTargetUsed"
                ref="territorialAtlas"
            ></territorial-atlas>

            <signature-details
                v-if="formSchema"
                @updateSignatureType="updateSignatureType"
                @updateCertSignPackage="updateCertSignPackages"
                @downloadFiles="downloadPdfFile"
                @validatePackage="validatePackage"
                @isSigned="applicationIsSignedOffline"
                ref="signatureDetails"
            ></signature-details>

            <payment-details
                v-if="formSchema"
                @updatePaymentType="updatePaymentType"
                ref="paymentDetails"
                :opDetailsText="opDetailsText"
            ></payment-details>

            <discount-details
                v-if="showDiscountsSection"
                :options="discountsOptions"
                ref="discountDetails"
                @updateSelectedDiscount="updateSelectedDiscount"
            ></discount-details>

            <application-summary
                v-if="formSchema"
                :formModels="formData"
                :signDetails="signDetails"
                :paymentDetails="paymentDetails"
                :requiredRealEstate="requiredRealEstate"
                ref="applicationSummary"
            ></application-summary>

            <div
                class="d-flex align-items-center justify-content-center mt-6"
                v-if="formSchema"
            >
                <st-button
                    v-if="!isPaid"
                    :callback="doPay"
                    :disabled="disablePayment"
                    :spinner="paymentLoading"
                >
                    {{ payButtonText }}
                </st-button>
                <st-button v-else :callback="doSign"
                    >{{ $t("APPLICATION.FORM.SIGN_BUTTON") }}
                </st-button>
            </div>
        </div>
        <confirm-sign-modal
            :applicationId="applicationId"
            ref="confirm-sign-document-modal"
            @signValidated="signValidated"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GenerateForm from "@/modules/forms/components/generate-form/GenerateForm.vue";
import Message from "@/shared/message/message";
import { ApplicationModel } from "@/modules/applications/models/application-model";
import Vue from "vue";
import TaxesStoreObserverMixin from "@/modules/applications/store/taxes-store-observer-mixin";
import ApplicationFormCommonMixin from "@/modules/applications/mixins/application-form-common-mixin";
import { RE_ENROLL_ERRORS } from "@/modules/applications/utils/constants";
import Errors from "@/shared/error/errors";
import { BNav, BNavItem, VBScrollspy } from "bootstrap-vue";
import { axiosInlineDownload } from "@/modules/applications/utils/axios-inline-downloader";

const { fields } = ApplicationModel;

export default {
    name: "ApplicationsForm",
    mixins: [TaxesStoreObserverMixin, ApplicationFormCommonMixin],
    components: {
        BNav,
        BNavItem,
        GenerateForm,
        TerritorialAtlas: () => import(/* webpackChunkName: "atlas" */ "@/modules/applications/components/territorial-atlas/TerritorialAtlasCard.vue"),
        ApplicationDetailsForm: () => import("@/modules/applications/components/ApplicationDetailsForm"),
        SignatureDetails: () => import("@/modules/applications/components/signature/SignatureDetails"),
        ApplicationSummary: () => import("@/modules/applications/components/summary/ApplicationSummary"),
        ConfirmSignModal: () => import("@/modules/applications/components/signature/ConfirmSignModal"),
        PaymentDetails: () => import("@/modules/applications/components/payment/ApplicationPayment"),
        DiscountDetails: () => import("@/modules/applications/components/discount/ApplicationDiscount"),
    },
    directives: {
        "b-scrollspy": VBScrollspy,
    },
    props: {
        appId: String
    },
    watch: {
        "record.form": function(newVal) {
            if (newVal) {
                this.formData = { ...this.formData, ...newVal };
            }
        },
    },
    data() {
        return {
            formSchema: null,
            formData: {},
            taxDetails: [],
            appDetails: {},
            selectedApplicationType: null,
            signDetails: {
                isOnline: true,
                package: null,
            },
            paymentStatus: "successful",
            identificationFilesUploaded: false,
            showConfirmSignModal: false,
            activeMenu: "applicationDetailsForm",
            scrollPosition: null,
            needPackageValidation: true,
            isPaymentPending: false,
            isUploadingPending: false,
            tax_category_conditions: null,
            paymentDetails: {
                payment_type: "CARD",
            },
            discountDetails: null,
            discountsOptions: null,
            isRealEstateTargetUsed: false,
            requiredRealEstate: false,
            hasGisAttachments: false,
            opDetailsText: null,
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            loading: "shared/loading",
            currentUser: "auth/currentUser",
            downloadToken: "applications/form/fileToken",
            signingStatus: "applications/form/signingStatus",
            uploadAccessToken: "applications/form/uploadAccessToken",
            totalTaxes: "applications/form/totalTaxes",
        }),
        applicationId() {
            return this.record?.id || "";
        },
        isPaid() {
            return !!this.record?.is_paid;
        },
        isLoading() {
            return (
                this.loading["applications/find"] ||
                this.paymentLoading ||
                this.isLoadingSaveDraft ||
                this.loading["applications/initializeOfflineSign"] ||
                this.loading["applications/previewDocumentByCitizen"]
            );
        },
        isLoadingSaveDraft() {
            return (
                this.loading["applications/create"] ||
                this.loading["applications/update"] ||
                this.isUploadingPending
            );
        },
        showTaxesSection() {
            return this.taxDetails.length;
        },
        tabs() {
            return [
                {
                    index: 0,
                    name: "APPLICATION.TABS.NEW_APPLICATION",
                    ref: "applicationDetailsForm",
                    id: "#application-details-target",
                    icon: "fas fa-edit",
                    show: true,
                },
                {
                    index: 1,
                    name: "APPLICATION.TABS.BENEFICIARY",
                    ref: "beneficiaryList",
                    id: "#beneficiary-target",
                    disabled: !this.selectedApplicationType,
                    icon: "fas fa-users",
                    show: true,
                },
                {
                    index: 2,
                    name: "APPLICATION.TABS.REAL_ESTATE",
                    ref: "realEstateList",
                    id: "#real-estate-target",
                    disabled: !this.selectedApplicationType,
                    icon: "fas fa-home",
                    show: true,
                },
                {
                    index: 3,
                    name: "APPLICATION.TABS.SIGN",
                    ref: "signatureDetails",
                    id: "#signature-details-target",
                    disabled: !this.selectedApplicationType,
                    icon: "fas fa-pencil-alt",
                    show: true,
                },
                {
                    index: 4,
                    name: "APPLICATION.TABS.SUMMARY",
                    ref: "applicationSummary",
                    id: "#summary",
                    disabled: !this.selectedApplicationType,
                    icon: "fas fa-check-circle",
                    show: true,
                },
            ];
        },
        paymentLoading() {
            return (
                this.loading["applications/create"] ||
                this.isUploadingPending ||
                this.loading["applications/pay"] ||
                this.isPaymentPending ||
                (this.paymentStatus !== "successful" &&
                    this.paymentStatus !== "failed")
            );
        },
        realEstatesList() {
            if (!this.formData?.real_estate_target?.length) return [];
            return this.formData.real_estate_target.filter(
                (el) => el?.coordinates?.length
            );
        },
        gisFilesLayers() {
            return this.record?.gis_files_layers;
        },
        loaderMessage() {
            return this.isUploadingPending ? this.$t('APPLICATION.NOTIFIERS.UPLOADING_FILES') : this.offlineSigningText;
        },
        cannotDownload() {
            if (!this.record) {
                return false;
            } else {
                if (this.record?.offlineSign?.files?.length > 0 || this.record?.is_paid) {
                    return true;
                }

                return false;
            }
        },
        showDiscountsSection() {
            return this.formSchema && this.discountsOptions?.length;
        },
        payButtonText() {
            if (this.totalTaxes === 0) {
                return this.$t("APPLICATION.FORM.CONTINUE_BUTTON");
            }
            return this.$t("APPLICATION.FORM.PAY_BUTTON");
        },
        applicationTypeId() {
            return this.$route.params.appId;
        },
        showTerritorialAtlasComponent() {
            return this.formSchema && (this.requiredRealEstate || this.hasGisAttachments);
        },
    },
    methods: {
        ...mapActions({
            doCreate: "applications/form/create",
            doUpdate: "applications/form/update",
            calculateTaxes: "applications/form/calculateTaxes",
            doFindApplicationType: "applications/form/findApplicationType",
            doFindForm: "applications/form/findForm",
            doUpload: "applications/form/upload",
            doPayApplication: "applications/form/pay",
            initializeOfflineSigning: "applications/form/initializeOfflineSigning",
            doDownload: "applications/form/downloadDocument",
            sendApplication: "applications/form/finalizeApplication",
            doEnrollment: "applications/form/enrollPerson",
            signDoc: "applications/form/signDocumentCitizen",
            initApplicationState: "applications/form/initFormState",
            doFindTaxCategory: "applications/form/findTaxCategory",
            doFind: "applications/form/find",
            doClear: "applications/form/clear",
            updateApplicationValidation: "applications/form/validation",
            doSetSelectedApplicationType: "applications/form/doSetSelectedApplicationType",
            updateOfflineSignDownloadStatus: "applications/form/updateOfflineSignDownloadStatus",
            setComputedTaxFields: "applications/form/doSetComputedTaxFields",
            doDownloadDocumentByCitizen: "applications/form/previewDocumentByCitizen",
            doSetDiscountDetails: "applications/form/doSetDiscountDetails",
        }),
        async updateFormData(data, key, realEstateTargetUsed) {
            Vue.set(this.formData, key, data);
            this.isRealEstateTargetUsed = realEstateTargetUsed;

            if (this.is_tax_category_conditional && this.tax_category_conditions) {
                const TAX_RELATED_FIELDS = Object.keys(this.tax_category_conditions);
                if (TAX_RELATED_FIELDS.includes(key)) {
                    const payload = {
                        [key]: data,
                    };
                    this.setComputedTaxFields(payload);
                }
            }

            if (key === 'gis-attachments' && !this.record?.id) {
                await this.saveAsDraft(true);
            }
        },
        changeApplicationType({ application_type_id }) {
            if (!application_type_id) {
                this.selectedApplicationType = null;
                return;
            }

            this.formSchema = null;
            this.formData = {};
            this.taxDetails = {};
            this.discountDetails = null;
            this.doSetDiscountDetails(null);
            this.appDetails = {};

            this.formData.application_type_id = application_type_id;
            this.formData.application_type_name = fields.application_type_id.options.find((el) => el.value === application_type_id)?.text;
            this.tax_category_conditions = fields.application_type_id.options.find((el) => el.value === application_type_id)?.tax_category_conditions;
            this.is_tax_category_conditional = fields.application_type_id.options.find((el) => el.value === application_type_id)?.is_tax_category_conditional;
            this.doSetSelectedApplicationType(application_type_id);

            this.selectedApplicationType = application_type_id;
            this.doFindApplicationType(application_type_id).then(
                (applicationTypeData) => {
                    const { form_citizen_id, tax_category_id, discounts, tenant_op_details_text } = applicationTypeData;
                    this.opDetailsText = tenant_op_details_text;
                    this.discountsOptions = discounts ?? null;

                    if (form_citizen_id) {
                        this.doFindForm(form_citizen_id).then((formData) => {
                            this.formSchema = formData.schema;
                            this.hasGisAttachments = !!formData.schema.list.find(item => item.type === 'gis-attachments');
                            this.requiredRealEstate = !!formData.schema.list.find(item => item.type === 'real-estate-list');
                        });
                    } else {
                        this.formSchema = null;
                        Message.error("APPLICATION.FORM.NO_TEMPLATE");
                    }
                }
            );
        },
        clearData(data) {
            this.formData = {...this.formData, ...data.form};
        },
        async generateData(asDraft) {
            const validateDetailsForm = await this.$refs.applicationDetailsForm.fv.validate();

            if (validateDetailsForm === "Valid") {
                try {
                    const genFormData = await this.$refs.generateForm.getData(asDraft);
                    const applicationDetailForm = this.$refs.applicationDetailsForm;

                    const { options, shared_fields, form } = genFormData;
                    let formLables = {};

                    for (let item in options) {
                        const opts = options[item].options;
                        if (opts?.length && typeof opts[0] === "object") {
                            const selectedOption = opts.find((opt) => opt.value === form[item]);
                            if (selectedOption) {
                                formLables[`${item}_label`] = selectedOption.label;
                            }
                        }
                    }
                    const formWithOptions = {
                        form: {
                            ...formLables,
                            ...form,
                            options,
                            shared_fields
                        },
                    };
                    return {
                        ...formWithOptions,
                        ...this.$refs.applicationDetailsForm.model,
                        application_type_id: applicationDetailForm.model.application_type_id,
                        application_type_name:
                            applicationDetailForm.fields.application_type_id.options.find(
                                (el) => el.value === applicationDetailForm.model.application_type_id).text,
                    };
                } catch (error) {
                    this.updateApplicationValidation(false);
                }
            }
        },
        async saveAsDraft(asDraft, redirect, isSigned) {
            let data = await this.generateData(asDraft);
            if (!data) return false;

            data.is_signed = isSigned ?? false;

            data.status = "draft";
            data.sign_details = this.signDetails;

            data.payment_details = this.paymentDetails;
            data.discount_details = this.discountDetails;
            const fileDataObj = this.getFileData(data.form);
            const {fileItems, existentFiles} = fileDataObj;
            const fileData = fileItems;

            let application = this.record ?? null;
            if (!this.record?.id) {
                application = await this.doCreate(data);
            }

            const uploadedFiles = [];
            let uploadedIdentificationFiles = [];
            let existingFiles = [];
            if (fileData && this.uploadAccessToken) {
                this.identificationFilesUploaded = true;
                this.isUploadingPending = true;
                const promises = [];
                for (let i = 0; i < Object.keys(fileData).length; i++) {
                    const inputFileName = Object.keys(fileData)[i];
                    if (inputFileName !== "identification_files" ) {
                        const files = fileData[inputFileName];
                        const currentUploadedFiles = await this.handleUploadFiles({
                            files,
                            uploadAccessToken: this.uploadAccessToken,
                        });
                        promises.push(currentUploadedFiles);
                        uploadedFiles[inputFileName] = currentUploadedFiles.map(
                            (el) => ({
                                file_id: el.id,
                                filename: el.initialFilename,
                                created_date: el.created_date,
                                file_type: "input",
                                created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                            })
                        );
                        uploadedIdentificationFiles = [
                            ...uploadedIdentificationFiles,
                            ...uploadedFiles[inputFileName]
                        ]
                    } else {
                        existingFiles = fileData["identification_files"];
                    }
                }
                Promise.all(promises)
                .finally(()=> {
                    this.isUploadingPending = false;
                })
            }

            const appId = application?.data?.id ?? application.id;
            const fileToUpload = {}
            Object.keys(existentFiles).forEach((key) => {
                if (uploadedFiles[key]) {
                    fileToUpload[key] = [...existentFiles[key], ...uploadedFiles[key]];
                }
            })
            const attachments = [
                ...existingFiles,
                ...uploadedIdentificationFiles
            ];

            const formObj = {
                ...data.form,
                ...fileToUpload,
                attachments: data?.form?.attachments && data.form.attachments.length ? data.form.attachments: attachments
            }

            if(this.record?.id && !this.record.gis_files?.length) {
                data.gis_files_layers = [];
            }

            application = await this.doUpdate({
                id: appId,
                values: {
                    ...data,
                    identification_files: attachments,
                    form: formObj
                },
            });
            await this.clearData(application);
            if (asDraft) {
                Message.success("APPLICATION.NOTIFIERS.DRAFT_SAVED_SUCCESS");
            }

            if (redirect) {
                this.$router.push({
                    name: "applications",
                });
            } else {
                return application?.data ?? application;
            }
        },
        async handleUploadFiles({files, uploadAccessToken}) {
            try {
                return await this.doUpload({
                    files,
                    uploadAccessToken
                });
            } catch (e) {
                this.isUploadingPending = false;
                this.isPaymentPending = false;
                throw e;
            }
        },
        goToSection(href, e) {
            e.stopPropagation();
            let $el;
            if (this.$refs[href]) {
                $el = this.$refs[href].$el;
            } else if (this.$refs.generateForm.$refs[href]) {
                $el = this.$refs.generateForm.$refs[href][0].$el;
            }
            if ($el) {
                const elementPosition = $el.offsetTop;
                window.scrollTo({
                    top: elementPosition,
                    behavior: "smooth",
                });
            }
        },
        updateSignatureType(isOnline) {
            this.signDetails = {
                isOnline,
            };
        },
        updateCertSignPackages(item) {
            this.signDetails = {
                ...this.signDetails,
                package: item,
            };
        },
        updatePaymentType(paymentType) {
            this.paymentDetails = {
                payment_type: paymentType,
            };
        },
        updateSelectedDiscount(discountId) {
            const discountDetails = this.discountsOptions.find(option => option.id === discountId);
            this.discountDetails = discountDetails;
            this.doSetDiscountDetails(discountDetails);
        },
        async doPay() {
            //need to check if the user has enough signatures
            if (
                this.signDetails.isOnline &&
                this.needPackageValidation &&
                !this.signDetails.package
            ) {
                Message.error("APPLICATION.NOTIFIERS.CHOOSE_SIGN_PACKAGE");
                return;
            }

            if (!this.signDetails.isOnline) {
                //check if signed
                if (!this.record || (this.record && !this.record.is_signed)) {
                    Message.error("APPLICATION.NOTIFIERS.OFFLINE_SIGN_FAILED");
                    return;
                }
            }

            this.isPaymentPending = true;

            let response = await this.saveAsDraft(false);

            if (!response) {
                this.isPaymentPending = false;
                return false;
            }

            //change amount after everything is done
            const payload = {
                id: this.record.id,
                data: {
                    amount: 1,
                    currency: this.$t("GENERAL.CURRENCY_LABEL"),
                    summary: [],
                },
            };

            if (this.signDetails.isOnline && this.signDetails.package) {
                payload.data.summary.push({
                    id: this.signDetails.package.id,
                    name: this.signDetails.package.name,
                    type: "CERTSIGN",
                    amount: this.signDetails.package.price_including_vat ?? 0,
                    signatures: this.signDetails.package.quantity ?? 0,
                });
            }
            this.doPayApplication(payload)
                .then((data) => {
                    //what happens if there is no payment url ?
                    if (
                        this.paymentDetails.payment_type === "OP" &&
                        data?.op_payment_id
                    ) {
                        this.$router.push({
                            name: "applicationsPaymentOrder",
                            params: { opPaymentId: data.op_payment_id, applicationId: this.record.id },
                        });
                    } else if (data?.bt_payment_form_url) {
                        window.location.replace(data.bt_payment_form_url);
                    } else {
                        this.isPaymentPending = false;
                        this.$router.push({ name: 'applicationEdit', params: { id: this.record.id }, query: { state: 'redirect' } });
                    }
                })
                .catch(() => {
                    this.isPaymentPending = false;
                });
        },
        async doSign() {
            let enrollmentRequest = {
                user_id: this.currentUser.user_id,
                email: this.currentUser.email,
                first_name: this.currentUser.first_name,
                last_name: this.currentUser.last_name,
                id_card_number: this.currentUser.id_card_number,
                id_card_serial: this.currentUser.id_card_serial,
                id_card_expiration_date:
                    this.currentUser.id_card_expiration_date,
                id_card_issuer: this.currentUser.id_card_issuer,
                cnp: this.currentUser.personal_numeric_code,
                phone_number: this.currentUser.phone_number,
                county: this.currentUser.county,
                type: 0,
            };

            const showModalInnerMethod = () => {
                this.showConfirmSignModal = true;
                this.$nextTick(() => {
                    this.$refs["confirm-sign-document-modal"].show();
                });
            };

            if (this.currentUser.enrolled) {
                try {
                    await this.signDoc({ id: this.record.id });
                    showModalInnerMethod();
                } catch (err) {
                    if (RE_ENROLL_ERRORS.includes(err)) {
                        await this.doEnrollment({
                            ...enrollmentRequest,
                            force: true,
                        });
                        await this.signDoc({ id: this.record.id });
                        showModalInnerMethod();
                        return;
                    }

                    Errors.handle(err);
                }
            }

            await this.doEnrollment(enrollmentRequest);
            await this.signDoc({ id: this.record.id });
            showModalInnerMethod();
        },
        async downloadPdfFile() {
            //save application
            const application = await this.saveAsDraft(false);
            if (!application) return false;

            const initializeSign = await this.initializeOfflineSigning({
                id: application.id,
                filesLocation: "files",
            });

            //offline sign available
            if (initializeSign?.offlineSign?.files) {
                this.updateOfflineSignDownloadStatus(true);
                const lastFile = initializeSign.offlineSign.files[initializeSign.offlineSign.files.length - 1];
                await axiosInlineDownload({
                    url: `${window.VUE_APP_API_URL}/files/${lastFile.file_id}?token=${this.downloadToken}`,
                    label: lastFile.filename,
                    type: 'application/pdf',
                });
            }
        },
        signValidated() {
            this.sendApplication(this.record.id).then((data) => {
                this.$router.push({
                    name: "applicationConfirmation",
                    params: {
                        id: data.identification_number,
                    },
                });
            });
        },
        updateScroll() {
            this.scrollPosition = window.scrollY;
        },
        validatePackage(need) {
            this.needPackageValidation = need;
        },
        async applicationIsSignedOffline(isSigned) {
            await this.saveAsDraft(false, false, isSigned);

            if (!this.identificationFilesUploaded) {
                await this.doFind({ id: this.record.id, loadStaff: false });
            }

            await this.$router.push({
                name: "applicationEdit",
                params: {
                    id: this.record.id,
                    tabRef: "applicationSummary",
                },
            });
        },
        async downloadDocumentByCitizen() {
            let response = await this.saveAsDraft(false);

            if (!response) {
                return false;
            }

            const { data, downloadAccessToken } = await this.doDownloadDocumentByCitizen({ id: this.record.id });

            window.open(`${window.VUE_APP_API_URL}/files/${data.file_id}?token=${downloadAccessToken}`,"_blank");
        },
    },
    mounted() {
        window.addEventListener("scroll", this.updateScroll);
    },
    async created() {
        this.initApplicationState();
        this.formSchema = null;
        this.formData = {};
        this.taxDetails = {};
        this.appDetails = {};
    },
    destroy() {
        this.isPaymentPending = false;
        window.removeEventListener("scroll", this.updateScroll);
    },
};
</script>
<style scoped>
@import "~quill/dist/quill.snow.css";
</style>
